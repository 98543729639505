export default {
    computed: {
        mgInternalCustomerHash: function() {
            return {
                "0": this.$t("customer_type.external_customer"),
                "1": this.$t("cusrtomer_type.internal_customer")
            };
        },

        mgInternalCustomer: function() {
            return this.$hash2select(this.mgInternalCustomerHash);
        },

        mgInvoiceTypesHash: function() {
            return {
                contract: this.$t("invoice_type.contract"),
                contract_regulation: this.$t(
                    "invoice_type.contract_regulation"
                ),
                subscription: this.$t("invoice_type.subscription"),
                charge: this.$t("invoice_type.charge"),
                project: this.$t("invoice_type.project")
            };
        },
        mgInvoiceTypes: function() {
            return this.$hash2select(this.mgInvoiceTypesHash);
        },

        mgVatZonesHash: function() {
            return {
                1: this.$t("vat_zones.home_country"),
                2: this.$t("vat_zones.eu"),
                3: this.$t("vat_zones.abroad"),
                4: this.$t("vat_zones.home_novat")
            };
        },
        mgVatZones: function() {
            return this.$hash2select(this.mgVatZonesHash);
        },

        mgGeneralActiveHash: function() {
            return {
                "1": this.$t("general.is_active"),
                "0": this.$t("general.is_inactive")
            };
        },

        mgGeneralActive: function() {
            return this.$hash2select(this.mgGeneralActiveHash);
        },

        mgSubscriptionTypeHash: function() {
            return {
                1: this.$t("subscriptions.type_regular"),
                2: this.$t("subscriptions.type_usage_based"),
                3: this.$t("subscriptions.type_multi_products")
            };
        },

        mgSubscriptionType: function() {
            return this.$hash2select(this.mgSubscriptionTypeHash);
        },

        mgSubscriptionStatusHash: function() {
            return {
                "1": this.$t("general.is_active"),
                "2": this.$t("general.is_inactive"),                
            };
        },
        mgSubscriptionStatus: function() {
            return this.$hash2select(this.mgSubscriptionStatusHash);
        },

        mgCurrenciesHash: function() {
            return {
                DKK: "DKK",
                EUR: "EUR",
                SEK: "SEK",
                GBP: "GBP"
            };
        },
        mgCurrencies: function() {
            return this.$hash2select(this.mgCurrenciesHash);
        },

        /**
         * Users active flag
         */
        mgUsersActiveHash: function() {
            return {
                "1": this.$t("users.user_is_active"),
                "0": this.$t("users.user_is_inactive")
            };
        },
        mgUsersActive: function() {
            return this.$hash2select(this.mgUsersActiveHash);
        },

        /**
         * Languages
         */
        mgLangs: function() {
            return this.$hash2select(this.mgLangsHash);
        },
        mgLangsHash: function() {
            return {
                da: this.$t("lang.danish"),
                en: this.$t("lang.english")
            };
        },

        /**
         * Contract types
         */
        mgContractTypeHash: function() {
            return {
                1: this.$t("contract.type_fixed_based_fee"),
                2: this.$t("contract.type_revenue_based")
            };
        },
        mgContractType: function() {
            return this.$hash2select(this.mgContractTypeHash);
        },

        mgIsFirstYear: function() {
            return {
                0: this.$t("contract.is_not_first_year"),
                1: this.$t("contract.is_first_year")
            };
        },

        mgAdjustmentType: function() {
            return {
                1: this.$t("contract.adj_type_none"),
                2: this.$t("contract.adj_type_fixed"),
                3: this.$t("contract.adj_type_net_price")
            };
        },

        /**
         * Adjustment subtype
         */
        mgAdjustmentSubtypeHash: function() {
            return {
                1: this.$t("contract.adj_subtype_fee"),
                2: this.$t("contract.adj_subtype_perc"),
                3: this.$t("contract.adj_subtype_both")
            };
        },
        mgAdjustmentSubtype: function() {
            return this.$hash2select(this.mgAdjustmentSubtypeHash);
        },

        /**
         * Shift frequency
         */
        mgShiftPaymentHash: function() {
            return {
                "": this.$t("shift_time.none"),
                "1 MONTH": this.$t("shift_time.1_month"),
                "2 MONTHS": this.$t("shift_time.2_months"),
                "3 MONTHS": this.$t("shift_time.3_months"),
                "4 MONTHS": this.$t("shift_time.4_months"),
                "5 MONTHS": this.$t("shift_time.5_months"),
                "6 MONTHS": this.$t("shift_time.6_months")
            };
        },
        mgShiftPayment: function() {
            return this.$hash2select(this.mgShiftPaymentHash);
        },

        /**
         * Default payment frequencies for all kinds of subscriptions
         */
        mgPaymentFrequencyHash: function() {
            return {
                "1 MONTH": this.$t("frequency.1_month"),
                "3 MONTHS": this.$t("frequency.1_quarter"),
                "6 MONTHS": this.$t("frequency.half_year"),
                "1 YEAR": this.$t("frequency.1_year")
            };
        },
        mgPaymentFrequency: function() {
            return this.$hash2select(this.mgPaymentFrequencyHash);
        },

        /**
         * Payment strategies (ahead, behind)
         */
        mgPaymentStrategyHash: function() {
            return {
                ahead: this.$t("payment.ahead"),
                behind: this.$t("payment.behind")
            };
        },
        mgPaymentStrategy: function() {
            return this.$hash2select(this.mgPaymentStrategyHash);
        }
    },
    methods: {
        $matchIn(arr, byKey) {
            let res = arr.find(el => el.value == byKey);

            if (typeof res === "undefined") {
                return "---";
            } else {
                return res.label;
            }
        },
        $matchInHash(hash, key) {
            if (key in hash) {
                return hash[key];
            } else {
                return "---";
            }
        },
        /**
         * Transform a hash into a select friendly array of objects
         * @param {} hash
         */
        $hash2select(hash) {
            let arr = [];

            for (let [key, text] in Object.entries(hash)) {
                arr.push({
                    label: text,
                    value: key
                });
            }

            return hash;
        }
    }
};
